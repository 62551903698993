import ErrorIcon from '@mui/icons-material/Error';
import { Alert, AlertTitle, Divider, SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { DateValidationError, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { useAnalyseLicenseRequestQuery, useCompleteLicenseRequestQuery } from "data/license-requests";
import { CompleteLicenseRequestResult, LicenseRequest } from "interfaces/license-request";
import { LicenceRequestAnalysis } from "interfaces/license-request-analysis";

import 'dayjs/locale/en-gb';

type DialogValue = {
  licenseRequestToken: string;
};

type StepProps = {
  goBack: () => void;
  goNext: () => void;
  nextButtonDisabled: [boolean, (value: boolean) => void];
  licenseRequestCode: [string, (value: string) => void];
  licenseRequestCodeError: [string | null, (value: string | null) => void];
  licenseRequestCodeAnalysis: [LicenceRequestAnalysis | null, (value: LicenceRequestAnalysis | null) => void];
  systemName: [string, (value: string) => void]
  customerName: [string, (value: string) => void];
  customerContact: [string, (value: string) => void];
  licenseQuantityRequest: [number, (value: number) => void];
  licenseExpiry: [Dayjs | null, (value: Dayjs | null) => void];
  licenseRequestResult: [CompleteLicenseRequestResult | null, (value: CompleteLicenseRequestResult) => void];
};

const steps = [
  {
    label: 'Enter license request code',
    component: (props: StepProps) => <EnterLicenseRequestCodeStep {...props} />,
    optional: false,
    canGoBack: false,
    canGoNext: true,
    nextButtonLabel: 'Verify'
  },
  {
    label: 'Verify',
    component: (props: StepProps) => <VerifyLicenseRequestCodeStep {...props} />,
    optional: false,
    canGoBack: false,
    canGoNext: false,
  },
  {
    label: 'Configure',
    component: (props: StepProps) => <ConfigureLicenseStep {...props} />,
    optional: false,
    canGoBack: false,
    canGoNext: true,
    nextButtonLabel: 'Register'
  },
  {
    label: 'Register',
    component: (props: StepProps) => <RegisterLicenseStep {...props} />,
    optional: false,
    canGoBack: false,
    canGoNext: false,
  },
  {
    label: 'Complete',
    component: (props: StepProps) => <CompleteLicenseStep {...props} />,
    optional: false,
    canGoBack: true,  // TODO: true only for testing
    canGoNext: true,
  },
];

export type LicenseDialogProps = {
  open: boolean;
  onClose: () => void;
} & DialogProps;

export function NewLicenseDialog(props: LicenseDialogProps) {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [licenseRequestCode, setLicenseRequestCode] = useState<string>('');
  const [licenseRequestCodeError, setLicenseRequestCodeError] = useState<string | null>(null);
  const [licenseRequestCodeAnalysis, setLicenseRequestCodeAnalysis] = useState<LicenceRequestAnalysis | null>(null);
  const [licenseQuantityRequest, setLicenseQuantityRequest] = useState<number>(0);
  const [systemName, setSystemName] = useState<string>('');
  const [customerName, setCustomerName] = useState<string>('');
  const [customerContact, setCustomerContact] = useState<string>('');
  const [licenseRequestResult, setLicenseRequestResult] = useState<CompleteLicenseRequestResult | null>(null);
  const [licenseExpiry, setLicenseExpiry] = useState<Dayjs | null>(null);

  const { onClose } = props;
  const activeStep = steps[activeStepIndex];
  const activeStepIsLast = activeStepIndex === (steps.length - 1);
  const showBackButton = activeStep.canGoBack && activeStepIndex !== 0;

  const handleNextClicked = () => {
    setActiveStepIndex((prevActiveStepIndex) => Math.min(prevActiveStepIndex + 1, (steps.length - 1)));
  }

  const handleBackClicked = () => {
    setActiveStepIndex((prevActiveStepIndex) => Math.max(prevActiveStepIndex - 1, 0));
  }
  
  const handleFinishClicked = () => {
    onClose();
  }

  return (
    <Dialog fullWidth={true} maxWidth='md' {...props} >
      <DialogTitle>New license</DialogTitle>
      <DialogContent>
        <DialogContentText>

        </DialogContentText>
        <Box>
          <Stepper activeStep={activeStepIndex} sx={{ mb: 4 }}>
            {steps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: ReactNode
              } = {};

              if (step.optional) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }

              if (index < activeStepIndex || activeStepIsLast) {
                stepProps.completed = true;
              }

              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep.component({
            goNext: handleNextClicked,
            goBack: handleBackClicked,
            nextButtonDisabled: [nextButtonDisabled, setNextButtonDisabled],
            licenseRequestCode: [licenseRequestCode, setLicenseRequestCode],
            licenseRequestCodeError: [licenseRequestCodeError, setLicenseRequestCodeError],
            licenseRequestCodeAnalysis: [licenseRequestCodeAnalysis, setLicenseRequestCodeAnalysis],
            licenseQuantityRequest: [licenseQuantityRequest, setLicenseQuantityRequest],
            licenseExpiry: [licenseExpiry, setLicenseExpiry],
            systemName: [systemName, setSystemName],
            customerName: [customerName, setCustomerName],
            customerContact: [customerContact, setCustomerContact],
            licenseRequestResult: [licenseRequestResult, setLicenseRequestResult],
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        {showBackButton && <Button onClick={handleBackClicked}>Back</Button>}
        <Box sx={{ position: 'relative' }}>
          {(!activeStepIsLast && activeStep.canGoNext) && <Button onClick={handleNextClicked} disabled={nextButtonDisabled} autoFocus>{activeStep.nextButtonLabel || 'Next'}</Button>}
          {/* <CircularProgress
            size={24}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          /> */}
        </Box>
        {activeStepIsLast && <Button onClick={handleFinishClicked} disabled={nextButtonDisabled} autoFocus>Finish</Button>}
      </DialogActions>
    </Dialog>
  );
}

function EnterLicenseRequestCodeStep(props: StepProps) {
  const { 
    licenseRequestCode: [licenseRequestCode, setLicenseRequestCode], 
    licenseRequestCodeError: [licenseRequestCodeError, ],
    nextButtonDisabled: [, setNextButtonDisabled]
  } = props;

  useEffect(() => {
    setNextButtonDisabled(licenseRequestCode.length === 0);
  }, [setNextButtonDisabled, licenseRequestCode.length]);

  return (
    <>
    <Grow in={true}>
      <TextField 
        id="license-request-code"
        label="License request code"
        helperText={licenseRequestCodeError ? licenseRequestCodeError : "This can be found on the login screen of a Decisio installation."}
        error={Boolean(licenseRequestCodeError)}
        value={licenseRequestCode}
        onChange={(e) => {
          setLicenseRequestCode(e.target.value);
        }}
        multiline
        fullWidth
        required
        autoComplete="off"
        rows={15}
      />
      </Grow>
    </>
  );
}

function VerifyLicenseRequestCodeStep(props: StepProps) {
  const { 
    licenseRequestCode: [licenseRequestCode, ], 
    licenseRequestCodeError: [, setLicenseRequestCodeError],
    licenseRequestCodeAnalysis: [, setLicenseRequestCodeAnalysis],
    goBack, goNext 
  } = props;
  const { licenseRequestAnalysis } = useAnalyseLicenseRequestQuery({
    licenseRequestKey: licenseRequestCode
    // licenseRequestKey: "7DAE319373103A2E8BE34109697ED62E4E169C284F39DAEF4F5AEC048C7EFBF18EDECF48ECCA5FC31414E87B88FD8DEE3AF1DCE1250ACBCEE6C0F2BE2593946DA02259089BB7DE7AA72E214701AAF57BC8B64846B3D3273B3C061A5B08931BDCDA186B5C3AC8946F2B2AF0D47FBF375E1D64E7AC328661AC4DBA05FD7E437B164A3C7FAF5FE1D55B433A4A140F729975EC658FA02817AEB73344F49569C9D98FD80AE39E24C6B7F382E3721BAB8F6CAFAAC5D83D96D8FC26C0BD9BD63B8560FCD42323B3BD62AF1DD6E0701DBE06B02F015EEFF88A845CF27FA1F832DED47A6BBF981044B293165D0231D52C5A07D34ECFE858D339B5DA947AF354CC0892E38855C2898B7F2F0233F86F99FB6E749AC349E9A65CD39D187EE7A4F222823630CC2591CE4705659BD6C12FF88DB8CBF0AC4376A083A92B287A997163EAF92915F5"
  });

  useEffect(() => {
    if (licenseRequestAnalysis) {
      if (licenseRequestAnalysis.error) {
        setLicenseRequestCodeAnalysis(null);
        setLicenseRequestCodeError(licenseRequestAnalysis.error);
        goBack();
      }
      else {
        setLicenseRequestCodeAnalysis(licenseRequestAnalysis);
        setLicenseRequestCodeError(null);
        goNext();
      }
    }
  }, [licenseRequestAnalysis, setLicenseRequestCodeAnalysis, setLicenseRequestCodeError, goNext, goBack]);

  return (
    <Fade in={true}>
      <Box sx={{ 
        display: 'flex', 
        height: 400,
        justifyContent: 'center', 
      }}>
        <LoaderWithText label='Verifying code...' />
      </Box>
    </Fade>
  );
}

function ConfigureLicenseStep(props: StepProps) {
  const { 
    nextButtonDisabled: [, setNextButtonDisabled],
    licenseRequestCodeAnalysis: [licenseRequestCodeAnalysis, ],
    systemName: [systemName, setSystemName],
    customerName: [customerName, setCustomerName],
    customerContact: [customerContact, setCustomerContact],
    licenseQuantityRequest: [licenseQuantityRequest, setLicenseQuantityRequest],
    licenseExpiry: [licenseExpiry, setLicenseExpiry],
  } = props;

  if (!licenseRequestCodeAnalysis) {
    throw new Error('License request code analysis is null.');
  }

  useEffect(() => {
    setLicenseQuantityRequest(licenseRequestCodeAnalysis.applicationCount);
    if (licenseRequestCodeAnalysis.installation) {
      setSystemName(licenseRequestCodeAnalysis.installation.systemName);
      setCustomerName(licenseRequestCodeAnalysis.installation.customerName);
      setCustomerContact(licenseRequestCodeAnalysis.installation.customerContact);
    }
  }, []);

  const [licenseDateExpiryError, setLicenseDateExpiryError] = useState<DateValidationError | null>(null);
  const licenseDateExpiryErrorMessage = useMemo(() => {
    switch (licenseDateExpiryError) {
      case 'minDate':
      case 'maxDate':
      case 'disablePast': {
        return 'Expiry date needs to be within a year from today.'
      }
      case 'invalidDate': {
        return 'The expiry date is not valid.'
      }
      default: {
        return null;
      }
    }
  }, [licenseDateExpiryError]);

  const isExistingInstallation = Boolean(licenseRequestCodeAnalysis.installation);

  useEffect(() => {
    setNextButtonDisabled(
      (licenseQuantityRequest < licenseRequestCodeAnalysis.applicationCount)
        || (!Boolean(licenseExpiry) || Boolean(licenseDateExpiryError))
        || (!isExistingInstallation && (!Boolean(systemName?.trim()) || !Boolean(customerName?.trim()) || !Boolean(customerContact?.trim())))
    );
  }, [setNextButtonDisabled, 
    licenseQuantityRequest, licenseRequestCodeAnalysis.applicationCount, licenseExpiry, licenseDateExpiryError,
    isExistingInstallation, systemName, customerName, customerContact
  ]);

  return (
    <>
    <Slide in={true} direction="left">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          '& .MuiTextField-root': { m: 2 },
          '& input[type=number]': { width: '50%' }
        }}
      >
        {licenseRequestCodeAnalysis.warnings?.length > 0 &&
          <Stack direction='column' justifyContent="space-between" spacing={1} marginBottom={1} padding={1}>
            {licenseRequestCodeAnalysis.warnings.map((warning, index) =>
              <Alert key={index} severity="warning">
                <AlertTitle>Warning</AlertTitle>
                {warning}
              </Alert>
            )}
          </Stack>
        }

        {/* <Divider>Customer system info</Divider> */}
        <Stack direction='row'>
          <TextField 
            id="sqlserver-name"
            label="SQL Server name"
            value={licenseRequestCodeAnalysis?.sqlServerName}
            fullWidth
            inputProps={{
              readOnly: true
            }}
            variant="filled"
            size="small"
          />
        </Stack>
        <Stack direction='row'>
          <TextField 
            id="decisio-database-name"
            label="Decisio database name"
            value={licenseRequestCodeAnalysis?.decisioDatabaseName}
            fullWidth
            inputProps={{
              readOnly: true
            }}
            variant="filled"
            size="small"
          />
          <TextField 
            id="current-application-count"
            label="Current application count"
            helperText="This is the current number of applications the installation has reported having."
            type="number"
            fullWidth
            inputProps={{
              readOnly: true
            }}
            variant="filled"
            size="small"
            value={licenseRequestCodeAnalysis.applicationCount}
          />
        </Stack>

        <Stack direction='row' justifyContent="space-between">
          <TextField 
            id="customer-name"
            label="Customer"
            value={customerName}
            required={!isExistingInstallation}
            onChange={(e) => {
              setCustomerName(e.target.value);
            }}
            fullWidth
            autoComplete="organization"
            inputProps={{
              readOnly: isExistingInstallation
            }}
            variant={isExistingInstallation ? 'filled' : 'standard'}
            size="small"
          />
          <TextField 
            id="contact-details"
            label="Contact details"
            value={customerContact}
            required={!isExistingInstallation}
            onChange={(e) => {
              setCustomerContact(e.target.value);
            }}
            fullWidth
            autoComplete="email tel"
            inputProps={{
              readOnly: isExistingInstallation
            }}
            variant={isExistingInstallation ? 'filled' : 'standard'}
            size="small"
          />
        </Stack>

        <Stack direction='row' justifyContent="space-between">
          <TextField 
            id="system-name"
            label="System name"
            value={systemName}
            required={!isExistingInstallation}
            onChange={(e) => {
              setSystemName(e.target.value);
            }}
            fullWidth
            inputProps={{
              readOnly: isExistingInstallation
            }}
            variant={isExistingInstallation ? 'filled' : 'standard'}
            size="small"
          />
          <TextField 
            id="max-applications"
            label="Licensed application count"
            fullWidth
            required
            autoComplete="off"
            helperText={
              licenseQuantityRequest < licenseRequestCodeAnalysis.applicationCount ? 
              "Count cannot be less than the current number of applications." 
              : "Enter the maximum number of applications the license will grant."
            }
            type="number"
            size="small"
            InputProps={{
              inputProps: {
                min: licenseRequestCodeAnalysis.applicationCount,
              }
            }}
            error={licenseQuantityRequest < licenseRequestCodeAnalysis.applicationCount}
            value={licenseQuantityRequest}
            onChange={(e) => {
              setLicenseQuantityRequest(Number(e.target.value));
            }}
          />

        </Stack>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <Stack direction='row' alignItems="center">
            <DatePicker 
              disablePast
              label='License expiry date'
              slotProps={{
                textField: {
                  required: true,
                  helperText: licenseDateExpiryErrorMessage
                }
              }}
              minDate={dayjs().add(1, 'day')}
              maxDate={dayjs().add(1, 'year')}
              value={licenseExpiry}
              onChange={(date) => {
                setLicenseExpiry(date);
              }}
              onError={(error) => setLicenseDateExpiryError(error)}
            />
            { (licenseDateExpiryErrorMessage || !licenseExpiry || !licenseExpiry.isValid()) ? '' : licenseExpiry.fromNow() }
          </Stack>
          </LocalizationProvider>

      </Box>
      </Slide>
    </>
  );
}

function RegisterLicenseStep(props: StepProps) {
  const { 
    licenseRequestCode: [licenseRequestCode, ],
    systemName: [systemName, ],
    customerName: [customerName, ],
    customerContact: [customerContact, ],
    licenseQuantityRequest: [licenseQuantityRequest, ],
    licenseExpiry: [licenseExpiry, ],
    licenseRequestResult: [, setLicenseRequestResult],
    goBack, goNext 
  } = props;
  const { mutate, data: licenseRequestResult, isError } = useCompleteLicenseRequestQuery();

  const licenseRequest = useMemo(() => {
    return {
      systemName: systemName,
      customerContact: customerContact,
      customerName: customerName,
      licenseRequestCode: licenseRequestCode,
      maximumApplications: licenseQuantityRequest,
      expiration: licenseExpiry!.toISOString() // TODO: Ideally avoid the non-null assertion operator
    } as LicenseRequest;
  }, [systemName, customerContact, customerName, licenseRequestCode, licenseQuantityRequest, licenseExpiry])

  useEffect(() => {
    mutate(licenseRequest);
  }, [mutate, licenseRequest]);

  useEffect(() => {
    if (licenseRequestResult) {
      setLicenseRequestResult(licenseRequestResult);
      goNext();
    }
  }, [setLicenseRequestResult, goNext, licenseRequestResult]);

  return (
    <Fade in={true}>
      <Box sx={{ 
        display: 'flex', 
        height: 400,
        justifyContent: 'center', 
      }}>
        {isError ? (
          <ErrorWithText errorMessage='Failed to register license.' />
        ) : (
          <LoaderWithText label='Registering license...' />
        )}
      </Box>
    </Fade>
  );
}

function CompleteLicenseStep(props: StepProps) {
  const { 
    nextButtonDisabled: [, setNextButtonDisabled],
    licenseRequestResult: [licenseRequestResult, ]
  } = props;

  if (!licenseRequestResult) {
    throw new Error('License request result was null.');
  }

  const clickToCopyText: string = 'Click to copy';

  const [codeCopiedSnackbarOpen, setCodeCopiedSnackbarOpen] = useState(false);
  const [copyCodeTooltipText, setCopyCodeTooltipText] = useState(clickToCopyText);
  const [codeHasBeingCopied, setCodeHasBeenCopied] = useState(false);
  const licenseCodeTextFieldRef = useRef<any>(null);

  useEffect(() => {
    setNextButtonDisabled(!codeHasBeingCopied);
  }, [setNextButtonDisabled, codeHasBeingCopied]);

  const handleCopiedToClipbaordSnackbarClose = (): void => {
    setCodeCopiedSnackbarOpen(false);
  }

  const handleMouseEnterLicenseCode = (e: any): void => {
    const textFieldElement = licenseCodeTextFieldRef.current;
    if (textFieldElement) {
      textFieldElement.focus();
      textFieldElement.select();
    }
  }

  const handleMouseLeaveLicenseCode = (e: any): void => {
    const textFieldElement = licenseCodeTextFieldRef.current;
    if (textFieldElement) {
      textFieldElement.blur();
    }
  }

  const handleLicenseCodeClick = async (): Promise<void> => {
    try {

      await navigator.clipboard.writeText(licenseRequestResult.licenseKey);
      // await navigator.clipboard.writeText(licenseCodeTextFieldRef.current.innerHTML);

      setCopyCodeTooltipText('Copied');
      setCodeCopiedSnackbarOpen(true);
    } catch (e) {
      // Failed to copy (probably insufficient permissions)
      setCopyCodeTooltipText('Failed to copy license code. Please copy it manually.');
      console.error('Failed to copy license code: ', e);
    } finally {
      setCodeHasBeenCopied(true);
    }
  }

  const StyledLicenseCodeTextField = styled(TextField)(`
    .MuiInputBase-input {
      cursor: pointer;
      font-family: monospace;
    }
  `);

  return (
    <>
    <Grow in={true}>
      <Box>
      <Typography variant='subtitle2' sx={{ mb: 2 }}>
        License succesfully created
      </Typography>
      <Typography variant='body1' sx={{ mb: 2 }}>
        Copy this key completely and send it to the customer. 
        They will need to enter this into the prompt Decisio gives for them to activate their license.
      </Typography>

      <Tooltip
        title={copyCodeTooltipText}
        onOpen={() => {
          setCopyCodeTooltipText(clickToCopyText);
        }}
        TransitionComponent={Zoom}
        followCursor
      >
        <StyledLicenseCodeTextField 
          inputRef={licenseCodeTextFieldRef}
          id="license-key"
          label="License key"
          // helperText="This can be found on the login screen of a Decisio installation."
          value={licenseRequestResult.licenseKey}
          multiline
          fullWidth
          variant="filled"
          rows={11}
          onMouseEnter={handleMouseEnterLicenseCode}
          onMouseLeave={handleMouseLeaveLicenseCode}
          onClick={handleLicenseCodeClick}
        />
      </Tooltip>
      <Snackbar
        open={codeCopiedSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleCopiedToClipbaordSnackbarClose}
        message="Copied license code"
      />
      </Box>
    </Grow>
    </>
  );
}

function LoaderWithText(props: {
  label: string
}) {
  const { label } = props;

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      gap: 2, 
      flexDirection: 'column'
    }}>
      <CircularProgress />
      <Typography
        variant="caption"
        component="div"
        color="text.secondary"
      >
        {label}
      </Typography>
    </Box>
  );
}

function ErrorWithText(props: {
  errorMessage: string
}) {
  const { errorMessage } = props;

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      gap: 2, 
      flexDirection: 'column'
    }}>
        <SvgIcon component={ErrorIcon} color='error' fontSize='large'/>
        <Typography>{errorMessage}</Typography>
    </Box>
  );
}