import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs, {  } from 'dayjs';
import { useState } from 'react';

import { Installation } from 'interfaces/installation';
import { License } from 'interfaces/license';

export const InstallationsTable = (props: {
  installations: Installation[],
}) => {
  const { installations } = props;

  return (
    <TableContainer component={Paper} variant='outlined'>
      <Table sx={{ minWidth: 650 }} aria-label="installations" size='small'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Customer</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell>System</TableCell>
            <TableCell>Application limit</TableCell>
            <TableCell>Next expiration</TableCell>
            {/* <TableCell>SQL Server Name</TableCell>
            <TableCell>Decisio Database Name</TableCell> */}
            {/* <TableCell /> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {installations.map((installation) => (
            <InstallationRow key={installation.id} installation={installation} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function InstallationRow(props: {
  installation: Installation,
}) {
  const { installation } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={installation.id}
        sx={{ '& > .MuiTableCell-root': { borderBottom: 'unset' }, borderBottom: 'unset' }}
      >
        <TableCell onClick={() => setOpen(!open)}>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {installation.customerName}
        </TableCell>
        <TableCell>{installation.customerContact}</TableCell>
        <TableCell>{installation.systemName}</TableCell>
        <TableCell>{FindMostRecentLicense(installation.licenses)?.maximumApplications ?? 0}</TableCell>
        <TableCell>{FindInstallationExpirationFromLicenses(installation.licenses)}</TableCell>
        {/* <TableCell align="right"> */}
          {/* <Stack direction="row" spacing={1}> */}
            {/* <IconButton aria-label="key"> */}
              {/* <KeyIcon sx={{ color: yellow['A700']}} fontSize='small' /> */}
              {/* <AppRegistrationIcon /> */}
              {/* <AutorenewIcon /> */}
            {/* </IconButton> */}
            {/* <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton> */}
          {/* </Stack> */}
        {/* </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <LicenseHistory licenses={installation.licenses} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function LicenseHistory(props: {
  licenses: License[],
}) {
  const { licenses } = props;

  return (
    <>
      <Typography variant='h6'>
        License History
      </Typography>
      {/* <TableContainer component={Paper}> */}
      <Table size='small' aria-label='licenses'>
        <TableHead>
          <TableRow>
            <TableCell>Licensor</TableCell>
            <TableCell>Activation date</TableCell>
            <TableCell>Expiration date</TableCell>
            <TableCell>Application limit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {licenses.map((license) => (
            <LicenseRow key={license.id} license={license} />
          ))}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
    </>
  );
}

function LicenseRow(props: {
  license: License,
}) {
  const { license } = props;

  return (
    <TableRow>
      <TableCell>{license.createdByObjectName}</TableCell>
      <TableCell>{dayjs(license.activation).format('LL')}</TableCell>
      <TableCell>{dayjs(license.expiration).format('LL')}</TableCell>
      <TableCell>{license.maximumApplications}</TableCell>
    </TableRow>
  );
}

function FindMostRecentLicense(licenses: License[]) {
  let currentMostRecent = licenses[0];

  // if (licenses && licenses.length > 0) {
  //   licenses.forEach((license) => {
  //     const createdParsed = dayjs(license.created);
  //     if (createdParsed && createdParsed.isValid()) {
  //       currentMostRecent = currentMostRecent
  //     }
  //   });
  // }

  return currentMostRecent;
}

function FindInstallationExpirationFromLicenses(licenses: License[]) {
  var dateNums = licenses.map((l) => dayjs(l.expiration).valueOf());
  var orderedLicensesDesc = Math.max(...dateNums);

  var installationExpiration = dayjs(orderedLicensesDesc);
  if (installationExpiration && installationExpiration.isValid()) {
    return installationExpiration.format('LL');
  }
  else {
    return '-';
  }
}