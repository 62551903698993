import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CircularProgress, Fade, Stack, Typography } from "@mui/material";

import { useState } from "react";

import { useInstallationsQuery } from "data/installations";

import { InstallationsTable } from "./InstallationsTable";
import { NewLicenseDialog } from "./new-license-dialog/NewLicenseDialog";
import { Installation } from 'interfaces/installation';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export function LicensedInstallationCard(props: {

}) {
  const [newLicenseDialogOpen, setNewLicenseDialogOpen] = useState(false);
  const { installations, isFetching } = useInstallationsQuery();

  const handleNewLicenseDialogClickOpen = () => {
    setNewLicenseDialogOpen(true);
  }

  const handleNewLicenseDialogClose = () => {
    setNewLicenseDialogOpen(false);
  }
  // const isFetching = true;
  return (
    <>
      {newLicenseDialogOpen && <NewLicenseDialog 
          onClose={handleNewLicenseDialogClose} 
          open={newLicenseDialogOpen} 
          // PaperProps={{ sx: { position: 'fixed', top: '10%' } }} 
        />}

      { isFetching ? (
        <>
          <Fade in={isFetching}>
            <Box sx={{ 
              display: 'flex', 
              // height: 800,
              justifyContent: 'center', 
            }}>
              <Loader label='' />
            </Box>
          </Fade>
        </>
      ) : (
        <>
          {/* <Fade in={true}>
            <Box> */}
          {/* <Card sx={{ p: 3 }}> */}

            <Stack direction='row' justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant='h5'>
                Installations
              </Typography>
              <Button variant='contained' startIcon={<AddIcon />} onClick={handleNewLicenseDialogClickOpen}>
                New License
              </Button>
            </Stack>

            <InstallationsTable installations={installations} />

            {/* <InstallationDataGridTableTest installations={installations} /> */}
            {/* </Box>
            </Fade> */}
          {/* </Card> */}
        </>
      )}
    </>
  );
}

function Loader(props: {
  label: string
}) {
  const { label } = props;

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      gap: 2, 
      flexDirection: 'column'
    }}>
      <CircularProgress />
      <Typography
        variant="caption"
        component="div"
        color="text.secondary"
      >
        {label}
      </Typography>
    </Box>
  );
}

// function InstallationDataGridTableTest(props: {
//   installations: Installation[],
// }) {
//   const { installations } = props;
//   const columns: GridColDef[] = [
//     {
//       field: 'customerName',
//       headerName: 'Customer',
//       flex: 1,
//     },
//     {
//       field: 'customerContact',
//       headerName: 'Contact',
//       flex: 1,
//     },
//     {
//       field: 'systemName',
//       headerName: 'System',
//       flex: 1,
//     },
//     {
//       field: 'maximumApplications',
//       headerName: 'Application limit',
//       flex: 1,
//     },
//     {
//       field: '',
//       headerName: 'Next expiration',
//       flex: 1,
//     }
//   ];

//   return (
//     <Box sx={{ height: 400, width: '100%', marginTop: 10, backgroundColor: 'white' }}>
//       <DataGrid 
//         rows={installations}
//         columns={columns}
//         disableRowSelectionOnClick
//         disableColumnSelector
        
//       />
//     </Box>
//   );
// }