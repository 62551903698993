import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from 'react';

import { loginRequest, msalConfig } from '../authConfig';
import { PageLayout } from '../components/PageLayout';
import { callMsGraph } from '../graph';
import dayjs, { extend, locale } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

/**
* Renders information about the signed-in user or a button to retrieve data about the user
*/
const ProfileContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  
  function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data      
      instance
          .acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
          })
          .then((response) => {
              callMsGraph(response.accessToken).then((response) => {
                console.log(response);

                setGraphData(response)
              });

              fetch(`/api/customers/7CFE5C70-92B0-4FE0-8406-02B9FC27832B`, {
                method: "GET",
                // mode: "cors",
                headers: {
                  authorization: `Bearer ${response.accessToken}`,
                }
              }).then(response => {
                return response.json();
              }).then(jsonResponse => {
                debugger;
                console.log(jsonResponse);
              });
          });
  }

  // useEffect(() => {
  //   RequestProfileData();
  // }, []);
  
  return (
      <>
          {/* <h5 className="card-title">Welcome {accounts[0].name}</h5>
          <br/>
          {graphData ? (
              <ProfileData graphData={graphData} />
          ) : (
              <Button variant="secondary" onClick={RequestProfileData}>
                  Request Profile Information
              </Button>
          )} */}
      </>
  );
};

/**
* If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
*/
const MainContent = () => {
  return (
      <div className="App">
          <AuthenticatedTemplate>
              <ProfileContent />
          </AuthenticatedTemplate>
  
          <UnauthenticatedTemplate>
              <h5>
                  <center>
                      Please sign-in to see your profile information.
                  </center>
              </h5>
          </UnauthenticatedTemplate>
      </div>
  );
};
  
function App() {

  const msalInstance = new PublicClientApplication(msalConfig);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#008f91'
      }
    }
  });

  locale('en-GB');
  extend(relativeTime);
  

  const queryCache = new QueryCache();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 2 * 60 * 1000,
        refetchOnWindowFocus: false,
        // suspense: true,
        retry: 0
      }
    },

    queryCache,
  });

  return (
    <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <PageLayout>
                <center>
                  <MainContent />
                </center>
              </PageLayout>
            </ThemeProvider>
          </QueryClientProvider>
        </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;