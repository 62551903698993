import { useQuery } from "react-query";

import * as installationsApi from 'api/installations';

export function useInstallationsQuery() {
  const { data, ...rest } = useQuery('installations', async () => {
    return installationsApi.getInstallations();
  });

  return {
    installations: data?.data!,
    ...rest
  }
}
