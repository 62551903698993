import { AxiosRequestConfig } from 'axios';

import { Installation } from 'interfaces/installation';
import { AnalyseLicenseRequestQuery, CompleteLicenseRequestResult, LicenseRequest } from 'interfaces/license-request';
import { LicenceRequestAnalysis } from 'interfaces/license-request-analysis';

import { api } from './api';

export function getInstallations(requestConfig?: AxiosRequestConfig) {
  return api.get<Installation[]>(`/installations`, requestConfig);
}

export function completeLicenseRequest(licenseRequest: LicenseRequest, requestConfig?: AxiosRequestConfig) {
  return api.post<CompleteLicenseRequestResult>(`/installations/license-request`, licenseRequest, requestConfig);
}

export function analyseLicenseRequest(licenseRequestQuery: AnalyseLicenseRequestQuery, requestConfig?: AxiosRequestConfig) {
  return api.put<LicenceRequestAnalysis>(`/installations/license-request:analyse`, licenseRequestQuery, requestConfig);
}