import { useMutation, useQuery, useQueryClient } from "react-query";

import * as installationsApi from 'api/installations';
import { AnalyseLicenseRequestQuery, LicenseRequest } from "interfaces/license-request";


export function useCompleteLicenseRequestQuery() {
  const queryClient = useQueryClient();

  return useMutation(
    (licenseRequest: LicenseRequest) => installationsApi.completeLicenseRequest(licenseRequest),
    {
      onSuccess: (completedLicenseRequestResult) => {
        queryClient.invalidateQueries("installations");
      }
    }
  );
}

export function useAnalyseLicenseRequestQuery(licenseAnalysisRequestQuery: AnalyseLicenseRequestQuery) {
  const { data, ...rest } = useQuery('license-request-analysis', async () => {
    return installationsApi.analyseLicenseRequest(licenseAnalysisRequestQuery);
  }, {
    cacheTime: 0,
  });

  return {
    licenseRequestAnalysis: data,
    ...rest
  }
}
