import { loginRequest, msalInstance } from "authConfig";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from "axios";

class ApiClient {
  readonly #axios: AxiosInstance;

  constructor() {
    this.#axios = axios.create({
      baseURL: '/api/'
    });

    this.#axios.interceptors.request.use(async (config) => {
      const account = msalInstance.getAllAccounts()[0];
      if (account) {
        const accessTokenResponse = await msalInstance.acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: account,
        });

        if (accessTokenResponse) {
          const accessToken = accessTokenResponse.accessToken;

          if (config.headers && accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
          }
        }
      }
      return config;
    });
  }

  async get<T>(url: string, config?: AxiosRequestConfig) {
    return this.request<T>(url, "GET", config);
  }

  async post<T, D = any>(url: string, data: D, config?: AxiosRequestConfig) {
    return this.request<T>(url, 'POST', { ...config, data }).then((res) => res.data);
  }

  async put<T, D = any>(url: string, data: D, config?: AxiosRequestConfig) {
    return this.request<T>(url, 'PUT', { ...config, data }).then((res) => res.data);
  }

  /**
   * Performs a request to the specified URL.
   * @param url The request URL.
   * @param method The request method.
   * @param data The request data.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async request<T>(
    url: string,
    method: Method,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.#axios.request<T>({ url, method, ...config });
  }
}

export const api = new ApiClient();

